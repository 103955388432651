import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Home
  },

  /* Employees */
  {
    path: '/hr/employees',
    name: 'Employees',
    component: () => import('../views/hr/Employees.vue')
  },
  {
    path: '/hr/employee/add',
    name: 'AddEmployee',
    component: () => import('../views/hr/AddEmployee.vue')
  },
  {
    path: '/hr/employee/:id',
    name: 'Employee',
    component: () => import('../views/hr/Employee.vue')
  },

  /* Employees Groups */
  {
    path: '/hr/groups',
    name: 'EmployeeGroups',
    component: () => import('../views/hr/EmployeeGroups.vue')
  },

  {
    path: '/hr/group/add',
    name: 'AddEmployeeGroup',
    component: () => import('../views/hr/AddEmployeeGroup.vue')
  },
  {
    path: '/hr/group/:id',
    name: 'EmployeeGroup',
    component: () => import('../views/hr/EmployeeGroup.vue')
  },

  /* Attendance */
  {
    path: '/hr/attendance',
    name: 'AttendanceOverview',
    component: () => import('../views/hr/AttendanceOverview.vue')
  },
  {
    path: '/hr/attendance/my',
    name: 'MyAttendance',
    component: () => import('../views/hr/MyAttendance.vue')
  },
  {
    path: '/hr/attendance/worktime',
    name: 'Worktime',
    component: () => import('../views/hr/Worktime.vue')
  },

  //Attendance Application
  {
    path: '/hr/attendance/applications',
    name: 'AttendanceApplications',
    component: () => import('../views/hr/AttendanceApplications.vue')
  },
  {
    path: '/hr/attendance/applications/my',
    name: 'MyAttendanceApplications',
    component: () => import('../views/hr/MyAttendanceApplications.vue')
  },
  {
    path: '/hr/attendance/application/add',
    name: 'CreateAttendanceApplication',
    component: () => import('../views/hr/CreateAttendanceApplication.vue')
  },
  {
    path: '/hr/attendance/application/:id',
    name: 'AttendanceApplication',
    component: () => import('../views/hr/AttendanceApplication.vue')
  },

  //Leaves Application
  {
    path: '/hr/leave/applications',
    name: 'LeaveApplications',
    component: () => import('../views/hr/LeaveApplications.vue')
  },
  {
    path: '/hr/leave/applications/my',
    name: 'MyLeaveApplications',
    component: () => import('../views/hr/MyLeaveApplications.vue')
  },
  {
    path: '/hr/leave/application/add',
    name: 'CreateLeaveApplication',
    component: () => import('../views/hr/CreateLeaveApplication.vue')
  },
  {
    path: '/hr/leave/application/:id',
    name: 'LeaveApplication',
    component: () => import('../views/hr/LeaveApplication.vue')
  },

  //Schedule
  {
    path: '/hr/schedule',
    name: 'Schedule',
    component: () => import('../views/hr/Schedule.vue')
  },

  //Work Managment
  {
    path: '/work/overview',
    name: 'WorkOverview',
    component: () => import('../views/work/WorkOverview.vue')
  },
  {
    path: '/work/my',
    name: 'MyWork',
    component: () => import('../views/work/MyWork.vue')
  },
  {
    path: '/work/add',
    name: 'AddWork',
    component: () => import('../views/work/AddWork.vue')
  },
  {
    path: '/work/allocation',
    name: 'HrAllocation',
    component: () => import('../views/work/HrAllocation.vue')
  },
  {
    path: '/work/timetable/overview',
    name: 'HrTimetable',
    component: () => import('../views/work/HrTimetable.vue')
  },
  {
    path: '/work/timetable/my',
    name: 'MyWorkTimetable',
    component: () => import('../views/work/MyWorkTimetable.vue')
  },
  {
    path: '/work/:id',
    name: 'Work',
    component: () => import('../views/work/Work.vue')
  },

  //Sales Managment

  //Customers
  {
    path: '/sales/pontential-customers',
    name: 'PotentialCustomers',
    component: () => import('../views/sales/PotentialCustomers.vue')
  },
  {
    path: '/sales/pontential-customer/add',
    name: 'AddPotentialCustomer',
    component: () => import('../views/sales/AddPotentialCustomer.vue')
  },
  {
    path: '/sales/pontential-customer/:id',
    name: 'PotentialCustomer',
    component: () => import('../views/sales/PotentialCustomer.vue')
  },

  {
    path: '/sales/customers',
    name: 'Customers',
    component: () => import('../views/sales/Customers.vue')
  },
  {
    path: '/sales/customer/add',
    name: 'AddCustomer',
    component: () => import('../views/sales/AddCustomer.vue')
  },
  {
    path: '/sales/customer/:id',
    name: 'Customer',
    component: () => import('../views/sales/Customer.vue')
  },


  //Orders
  {
    path: '/sales/orders',
    name: 'Orders',
    component: () => import('../views/sales/Orders.vue')
  },
  {
    path: '/sales/order/:id',
    name: 'Order',
    component: () => import('../views/sales/Order.vue')
  },
  {
    path: '/sales/order/add',
    name: 'AddOrder',
    component: () => import('../views/sales/AddOrder.vue')
  },

  //SNS
  {
    path: '/sales/sns',
    name: 'SNS',
    component: () => import('../views/sales/SNS.vue')
  },
  {
    path: '/sales/sns/setting',
    name: 'SNSSetting',
    component: () => import('../views/sales/SNSSetting.vue')
  },

  //Payroll
  {
    path: '/financial/payroll/overview',
    name: 'PayrollOverview',
    component: () => import('../views/financial/PayrollOverview.vue')
  },
  {
    path: '/financial/payroll/addition',
    name: 'AdditionPayrolls',
    component: () => import('../views/financial/AdditionPayrolls.vue')
  },
  {
    path: '/financial/payroll/addition/add',
    name: 'AddAdditionPayroll',
    component: () => import('../views/financial/AddAdditionPayroll.vue')
  },
  {
    path: '/financial/payroll/addition/:id',
    name: 'AdditionPayroll',
    component: () => import('../views/financial/AdditionPayroll.vue')
  },
  {
    path: '/financial/payroll/:id',
    name: 'Payroll',
    component: () => import('../views/financial/Payroll.vue')
  },

  //Expense
  {
    path: '/financial/expense/overview',
    name: 'ExpenseOverview',
    component: () => import('../views/financial/ExpenseOverview.vue')
  },
  {
    path: '/financial/expense/add',
    name: 'CreateExpense',
    component: () => import('../views/financial/CreateExpense.vue')
  },
  {
    path: '/financial/expense/my',
    name: 'MyExpense',
    component: () => import('../views/financial/MyExpenses.vue')
  },
  {
    path: '/financial/expense/statistic/staff',
    name: 'StaffExpenseStatistics',
    component: () => import('../views/financial/StaffExpenseStatistics.vue')
  },
  {
    path: '/financial/expense/statistic/type',
    name: 'ExpenseTypeStatistics',
    component: () => import('../views/financial/ExpenseTypeStatistics.vue')
  },
  {
    path: '/financial/expense/:id',
    name: 'Expense',
    component: () => import('../views/financial/Expense.vue')
  },

  //Payments
  {
    path: '/financial/payment/overview',
    name: 'Payments',
    component: () => import('../views/financial/Payments.vue')
  },
  {
    path: '/financial/payment/add',
    name: 'CreatePayment',
    component: () => import('../views/financial/CreatePayment.vue')
  },
  {
    path: '/financial/payment/completed',
    name: 'CompletedPayments',
    component: () => import('../views/financial/CompletedPayments.vue')
  },
  {
    path: '/financial/payment/statistics',
    name: 'PaymentStatistics',
    component: () => import('../views/financial/PaymentStatistics.vue')
  },
  {
    path: '/financial/payment/:id',
    name: 'Payment',
    component: () => import('../views/financial/Payment.vue')
  },
  // Trade
  {
    path: '/financial/trade/normal',
    name: 'NormalTrades',
    component: () => import('../views/financial/NormalTrades.vue')
  },
  {
    path: '/financial/trade/transaction',
    name: 'TransactionTrades',
    component: () => import('../views/financial/TransactionTrades.vue')
  },
  {
    path: '/financial/trade/add',
    name: 'CreateTrade',
    component: () => import('../views/financial/CreateTrade.vue')
  },
  {
    path: '/financial/trade/:id',
    name: 'Trade',
    component: () => import('../views/financial/Trade.vue')
  },

  // Other Income / Outcome
  {
    path: '/financial/income-outcome',
    name: 'OtherIncomeOutcomes',
    component: () => import('../views/financial/OtherIncomeOutcomes.vue')
  },
  {
    path: '/financial/income-outcome/add',
    name: 'CreateInOutItem',
    component: () => import('../views/financial/CreateInOutItem.vue')
  },
  {
    path: '/financial/income-outcome/:id',
    name: 'InOutItem',
    component: () => import('../views/financial/InOutItem.vue')
  },

  // Other Income / Outcome
  {
    path: '/financial/statement',
    name: 'Statements',
    component: () => import('../views/financial/Statements.vue')
  },
  {
    path: '/financial/statement/add',
    name: 'CreateStatement',
    component: () => import('../views/financial/CreateStatement.vue')
  },
  {
    path: '/financial/statement/:id',
    name: 'Statement',
    component: () => import('../views/financial/Statement.vue')
  },

  //Financial Setting
  {
    path: '/financial/setting',
    name: 'FinancialSetting',
    component: () => import('../views/financial/FinancialSetting.vue')
  },

  //Profits Report
  {
    path: '/financial/report',
    name: 'ProfitLossReport',
    component: () => import('../views/financial/ProfitLossReport.vue')
  },

  //Admin Report
  {
    path: '/report/admin',
    name: 'AdminReport',
    component: () => import('../views/report/AdminReport.vue')
  },
  {
    path: '/report/my',
    name: 'MyReport',
    component: () => import('../views/report/MyReport.vue')
  },

  //Backup
  {
    path: '/backup',
    name: 'Backup',
    component: () => import('../views/report/Backup.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
